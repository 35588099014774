<template>
  <st-side-page class="financial-page">
    <portal to="page-name">Financeiro</portal>
    <template slot="side">
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in availableMenus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view/>
  </st-side-page>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      menus: [
        {
          path: '/financial/',
          label: 'Dashboard',
          available: true,
        },
        {
          path: '/financial/billing-attendances',
          label: 'Atendimentos',
          available: true,
        },
        {
          path: '/financial/billing-accounts',
          label: 'Contas',
          available: true,
        },
        {
          path: '/financial/billing-invoices',
          label: 'Faturas',
          available: true,
        },
        {
          path: '/financial/billing-transfer',
          label: 'Repasse dos profissionais',
          available: true,
        },
        {
          path: '/financial/transactions',
          label: 'Movimentação financeira',
          available: true,
        },
        {
          path: '/financial/statements',
          label: 'Extrato',
          available: true,
        },
        {
          path: '/financial/budgets',
          label: 'Orçamentos',
          available: true,
        },
        {
          path: '/financial/invoices',
          label: 'Notas fiscais',
          available: ({ settings }) => (
            settings.invoice && !!settings.invoice.mappingId
          ),
        },
        {
          path: '/financial/reports',
          label: 'Relatórios',
          available: true,
        },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    branch() {
      return this.user.branch;
    },
    availableMenus() {
      return this.menus.filter(({ available }) => {
        if (typeof available === 'function') {
          return available({ settings: this.branch.settings });
        }
        return available;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.financial-page {
  &.side-page {
    .side-page-side {
      flex: 0 0 11rem;
    }
  }
  .menu {
    .router-link-exact-active {
      color: $primary-color;
      & + svg {
        color: $primary-color;
      }
    }
  }
}
</style>
